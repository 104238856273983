import React from 'react'
import { Switch } from 'react-router-dom'
import RotaNaoAutenticada from './Componentes/RotaNaoAutenticada'
import RotaAutenticada from './Componentes/RotaAutenticada'
import Login from './Telas/Login'
import Home from './Telas/Home'
import Financeiro from './Telas/Financeiro'
import Ingressos from './Telas/Ingressos'
import Transfer from './Telas/Transfer'

export default function ({ childProps }) {
  return (
    <Switch>
      <RotaNaoAutenticada exact path='/login' component={Login} />
      <RotaAutenticada exact path='/' component={Home} />
      <RotaAutenticada exact path='/financeiro' component={Financeiro} />
      <RotaAutenticada exact path='/ingressos' component={Ingressos} />
      <RotaAutenticada exact path='/transfer' component={Transfer} />
    </Switch>
  )
}
