import React, { Component } from 'react'
import styled from 'styled-components'
import ReactTable from 'react-table'
import Loading from '../Componentes/Loading'
import ChartsFinanceiros from '../Componentes/ChartsFinanceiros'
import Dinheiro from '../Componentes/Dinheiro'
import moment from 'moment'
import * as CONFIG from '../config.json'
const token = 'JWT ' + sessionStorage.getItem('token')

const Colunas = [
  {
    accessor: "hotelName",
    Header: "Nome do Hotel"
  }, {
    accessor: "totalBookings",
    Header: "Reservas",
    width: 130,
    Cell: row => (
      <center>{row.value}</center>
    )
  }, {
    accessor: "commission",
    Header: "Contrapartida",
    width: 140,
    Cell: row => (
      <center>{new Dinheiro('BRL').formata(row.value)}</center>
    )
    // }, {
    //     accessor: "gratuities",
    //     Header: "Cortesias",
    //     width: 130,
    //     render: row => (
    //         <p className="center">{row.value}</p>
    //     )
  }]

const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 30px 20px;
`

const Tabela = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 30px;
`

const Resumo = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  & li {
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 3px 3px rgba(72,72,72,0.1);
    -moz-box-shadow: 0px 0px 3px 3px rgba(72,72,72,0.1);
    box-shadow: 0px 0px 3px 3px rgba(72,72,72,0.1);
    width: 100%;
    margin: 15px;
    font-size: 1.2rem;
    padding: 20px;
    text-align: center;
    @media (min-width: 650px) {
      width: calc(50% - 30px);
    }
    @media (min-width: 1024px) {
      width: calc(25% - 60px);
    }
    & p:first-child {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #48484848;
    }
    & p:not(:first-child) {
      color: #00ade2;
      font-weight: bold;
    }
  }
`

export default class TelaInicial extends Component {
  constructor() {
    super()
    this.state = {
      bookings: [],
      startDate: moment.utc().format('YYYY-MM-DD'),
      endDate: moment.utc().add(3, 'days').format('YYYY-MM-DD'),
      loading: true,
      contrapartidaRiR: 0,
      receitaTotalOffline: 0,
      receitaTotalOnline: 0,
      receitaHoteis: 0,
      reservasPorDia: [],
      reservasPorHotel: []
    }
  }

  async componentWillMount() {
    let _this = this
    let body = document.querySelector('body')
    let { COMMISSION, OFFLINE_COMMISSION, ONLINE_COMMISSION, TOTAL, BOOKINGS } = CONFIG.API.FINANCES
    let urls = [COMMISSION, OFFLINE_COMMISSION, ONLINE_COMMISSION, TOTAL, BOOKINGS.HOTEL]
    body.style.overflow = 'hidden'
    await Promise.all(
      urls.map(url => fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': token
        },
      })
        .then(res => res.json())))
      .then(function (res) {
        _this.setState({
          contrapartidaRiR: res[0].total || 0,
          receitaTotalOffline: res[1].totalOffline || 0,
          receitaTotalOnline: res[2].totalOnline || 0,
          receitaHoteis: res[3].totalTransactioned || 0,
          reservasPorHotel: res[4] || [],
          loading: false
        }, () => body.style.overflow = 'auto')
      })
  }

  agrupaPorHotel = (lista) => {
    return lista.reduce((array, item) => {
      let verificaSeExiste = array.findIndex(itemFind => itemFind.hotelName === item.hotelName)
      if(verificaSeExiste !== -1) {
        array[verificaSeExiste].commission += item.commission
        array[verificaSeExiste].totalBookings += item.totalBookings
      } else {
        array.push(item)
      }
      return array
    }, [])
  }

  render() {
    return (
      <Container>
        <Loading isLoading={this.state.loading} />
        <Resumo>
          <li>
            <p>Total de Receita de Hoteis</p>
            <p>{new Dinheiro('BRL').formata(this.state.receitaHoteis)}</p>
          </li>
          <li>
            <p>Total de Contrapartida do Rock In Rio</p>
            <p>{new Dinheiro('BRL').formata(this.state.contrapartidaRiR)}</p>
          </li>
          <li>
            <p>Contrapartida de Pagamento Online</p>
            <p>{new Dinheiro('BRL').formata(this.state.receitaTotalOnline)}</p>
          </li>
          <li>
            <p>Contrapartida de Pagamento no Hotel</p>
            <p>{new Dinheiro('BRL').formata(this.state.receitaTotalOffline)}</p>
          </li>
        </Resumo>
        <ChartsFinanceiros />
        <Tabela>
          <ReactTable
            defaultPageSize={20}
            data={this.agrupaPorHotel(this.state.reservasPorHotel)}
            columns={Colunas}
            expanderColumnWidth={35}
            previousText={'Anterior'}
            nextText={'Próxima'}
            loadingText={'Carregando...'}
            noDataText={'Nenhum dado encontrado'}
            pageText={'Página'}
            ofText={'de'}
            rowsText={'linhas'}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]).toLowerCase().indexOf(String(filter.value).toLowerCase()) > -1}
          />
        </Tabela>
      </Container>
    )
  }
}
