import React, { Component } from 'react'
import styled from 'styled-components'
import * as CONFIG from '../config.json'
import Loading from '../Componentes/Loading'
import Dinheiro from '../Componentes/Dinheiro'
// import ReactTable from 'react-table'
import moment from 'moment'
const token = 'JWT ' + sessionStorage.getItem('token')

const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 30px 20px;
`

// const TableHeaders = [{
//   accessor: "hotel",
//   Header: "Hotel",
//   Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
// }, {
//   accessor: "ticketDate",
//   Header: "Data",
//   Cell: row => <div style={{ textAlign: "center" }}>{moment.utc(row.value).format('DD/MM/YYYY')}</div>
// }, {
//   accessor: "totalTickets",
//   Header: "Tickets Vendidos",
//   Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
// }, {
//   accessor: "totalRevenue",
//   Header: "Receita",
//   Cell: row => <div style={{ textAlign: "center" }}>{new Dinheiro('BRL').formata(row.value)}</div>
// }]

const Select = styled.select`
  font-size: 16px;
  background-color: #FFF;
  border-radius: 1px;
  font-weight: 300;
  color: #767676;
  cursor: pointer;
  width: 250px;
  padding: 6px 10px;
  box-sizing: border-box;
  border-top: 1px solid #CCC;
  border-right: 1px solid #CCC;
  border-left: 1px solid #CCC;
  transition: all 0.2s linear;
`

const Resumo = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  & li {
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 3px 3px rgba(72,72,72,0.1);
    -moz-box-shadow: 0px 0px 3px 3px rgba(72,72,72,0.1);
    box-shadow: 0px 0px 3px 3px rgba(72,72,72,0.1);
    width: 100%;
    margin: 15px;
    font-size: 1.2rem;
    padding: 20px;
    text-align: center;
    @media (min-width: 650px) {
      width: calc(50% - 30px);
    }
    @media (min-width: 1024px) {
      width: calc(25% - 60px);
    }
    & p:first-child {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #48484848;
    }
    & p:not(:first-child) {
      color: #00ade2;
      font-weight: bold;
    }
  }
`
export default class TelaInicial extends Component {
  constructor () {
    super()
    this.state = {
      dadosDeIngressos: [],
      dadosDosCards: [],
      eventoSelecionado: 0,
      loading: true
    }
  }

  async componentDidMount () {
    let body = document.querySelector('body')
    body.style.overflow = 'hidden'
    await window.fetch(CONFIG.API.TICKETS.HOTEL, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      },
    }).then(response => response.json()).then(json => {
      this.setState({
        dadosDeIngressos: json
      }, () => body.style.overflow = 'auto')
    }).catch(error => console.log("Erro: " + error))

    await fetch(CONFIG.API.TICKETS.DAY, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      },
    }).then(response => response.json()).then(json => {
      this.setState({
        loading: false,
        dadosDosCards: json,
        eventoSelecionado: json.length > 0 ? json[0].eventId : 0
      }, () => body.style.overflow = 'auto')
    }).catch(error => console.log("Erro: " + error))
  }

  retornaListaDeIdDeEventos = () => {
    return this.state.dadosDosCards.reduce((lista, item) => {
      !lista.find(evento => evento.eventId === item.eventId) && lista.push(item)
      return lista
    }, [])
  }

  mudaEventoSelecionado = (evento) => {
    this.setState({ eventoSelecionado: parseInt(evento.target.value) })
  }

  render () {
    return (
      <Container>
        <Loading isLoading={this.state.loading} />
        <Select onChange={this.mudaEventoSelecionado}>
          {this.retornaListaDeIdDeEventos().map((item, i) => <option key={i} value={item.eventId}>{item.eventName}</option>)}
        </Select>
        <Resumo>
          {this.state.dadosDosCards && this.state.dadosDosCards.filter(item => item.eventId === this.state.eventoSelecionado).map((item, i) => <li key={i}>
            <p style={{ fontWeight: 600 }}>{moment.utc(item.ticketDate).format('DD/MM')}</p>
            <p>Tickets vendidos: {item.totalTickets}</p>
            <p>{new Dinheiro('BRL').formata(item.totalRevenue)}</p>
          </li>)}
        </Resumo>
        <Resumo>
          <li>
            <p>Total de tickets vendidos </p>
            <p>{this.state.dadosDosCards.filter(item => item.eventId === this.state.eventoSelecionado).reduce((total, item) => total + item.totalTickets, 0)}</p>
          </li>
          <li>
            <p>Receita total </p>
            <p>{new Dinheiro('BRL').formata(this.state.dadosDosCards.filter(item => item.eventId === this.state.eventoSelecionado).reduce((total, item) => total + item.totalRevenue, 0))}</p>
          </li>
        </Resumo>
        {/* <ReactTable
          defaultPageSize={20}
          data={this.state.dadosDeIngressos.filter(item => item.eventId === this.state.eventoSelecionado)}
          columns={TableHeaders}
          expanderColumnWidth={35}
          previousText={'Anterior'}
          nextText={'Próxima'}
          loadingText={'Carregando...'}
          noDataText={'Nenhum dado encontrado'}
          pageText={'Página'}
          ofText={'de'}
          rowsText={'linhas'}
          filterable
          defaultFilterMethod={(filter, row) => {
            return String(row[filter.id]).toLowerCase().indexOf(String(filter.value).toLowerCase()) > -1
          }}
        /> */}
      </Container>
    )
  }
}
