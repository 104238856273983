import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment'
import styled from 'styled-components'
import * as CONFIG from '../config.json'

const Form = styled.form`
  padding: 20px;
  margin: 0 auto;
  align-items: center;
  max-width: 1024px;
  width: 100%;
  @media  (min-width: 500px) {
    display: flex; 
  }
`

const Button = styled.button`
  text-decoration: none;
  color: #fff;
  background-color: #26a69a;
  text-align: center;
  letter-spacing: .5px;
  transition: .2s ease-out;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  background-color: #031027;
  margin-bottom: 10px;
  &:hover {
    background-color: #0F2E63 !important;
  }
`

const Date = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin: 0 20px 20px 0;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: all 0.3s;
  &::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
  }
  &:focus{
  border-bottom: 1px solid #009688;
  }
`

const token = 'JWT ' + sessionStorage.getItem('token');

export default class ChartsFinanceiro extends Component {
  constructor() {
    super();
    this.state = {
      reservas: {
        startDate: moment.utc().subtract(30, 'day').format('YYYY-MM-DD'),
        endDate: moment.utc().format('YYYY-MM-DD'),
        chartData: []
      },
      financeiro: {
        startDate: moment.utc().subtract(30, 'day').format('YYYY-MM-DD'),
        endDate: moment.utc().format('YYYY-MM-DD'),
        chartData: []
      }
    }

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange = (e, tipo) => {
    var dados = this.state[tipo]
    dados[e.target.id] = e.target.value
    this.setState({
      [tipo]: dados
    }, () => console.log(this.state));
  }


  getChartDataFinanceiro = (e) => {
    if (e) e.preventDefault()
    var _this = this;
    fetch(CONFIG.API.FINANCES.BOOKINGS.DAY.replace('{{ startDate }}', this.state.financeiro.startDate).replace('{{ endDate }}', this.state.financeiro.endDate), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      },
    }).then(function (response) {
      return response.json()
    }).then(function (json) {
      var { financeiro } = _this.state
      financeiro.chartData = json
      _this.setState({
        financeiro
      })
    })
      .catch(function (error) {
        console.log("Erro: " + error)
      });
  }

  getChartDataReservas = (e) => {
    if (e) e.preventDefault()
    var _this = this;
    fetch(CONFIG.API.FINANCES.BOOKINGS.DAY.replace('{{ startDate }}', this.state.reservas.startDate).replace('{{ endDate }}', this.state.reservas.endDate), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      },
    }).then(function (response) {
      return response.json()
    }).then(function (json) {
      var { reservas } = _this.state
      reservas.chartData = json
      _this.setState({
        reservas
      })
    })
      .catch(function (error) {
        console.log("Erro: " + error)
      });
  }

  componentDidMount() {
    this.getChartDataFinanceiro()
    this.getChartDataReservas()
  }

  render() {
    let chartLabelsFinanceiros = this.state.financeiro.chartData.map((data) =>
      moment.utc(data.whichDate).format('DD/MM')
    )
    let chartLabelsReservas = this.state.reservas.chartData.map((data) =>
      moment.utc(data.whichDate).format('DD/MM')
    )
    let chartDataBookings = this.state.reservas.chartData.map((data) =>
      data.bookings
    )
    let chartDataNights = this.state.reservas.chartData.map((data) =>
      data.nights
    )
    let chartDataFinanceiro = this.state.financeiro.chartData.map((data) =>
      (data.onlineCommission + data.offlineCommission).toFixed(2)
    )

    const chartData = {
      labels: chartLabelsReservas,
      datasets: [{
        label: 'Reservas',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: chartDataBookings
      }, {
        label: 'Diárias',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(224,75,202,0.4)',
        borderColor: 'rgba(224,75,202,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(224,75,202,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(224,75,202,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: chartDataNights
      }]
    }

    const chartData2 = {
      labels: chartLabelsFinanceiros,
      datasets: [{
        label: 'Contrapartida (R$)',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(45,130,24,0.4)',
        borderColor: 'rgba(45,130,24,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(45,130,24,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(45,130,24,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: chartDataFinanceiro
      }]
    }

    return (
      <div>
        <hr />
      <Form onSubmit={this.getChartDataFinanceiro}>
        <Date type='date' onChange={e => this.handleInputChange(e, 'financeiro')} id='startDate' defaultValue={this.state.financeiro.startDate} />
        <Date type='date' onChange={e => this.handleInputChange(e, 'financeiro')} id='endDate' defaultValue={this.state.financeiro.endDate} />
        <Button type='submit'>Filtrar</Button>
      </Form>
      <Line data={chartData2} />
      <br />
      <br />
      <Form onSubmit={this.getChartDataReservas}>
        <Date type='date' onChange={e => this.handleInputChange(e, 'reservas')} id='startDate' defaultValue={this.state.reservas.startDate} />
        <Date type='date' onChange={e => this.handleInputChange(e, 'reservas')} id='endDate' defaultValue={this.state.reservas.endDate} />
        <Button type='submit'>Filtrar</Button>
      </Form>
      <Line data={chartData} />
      <hr />
    </div>
    )
  }
}
