/** Classe de auxílio de formatação de moeda
 * @param {String} codigoMoeda Código da moeda
 */
export default class Dinheiro {
  constructor (codigoMoeda) {
    const MOEDA = this.moedas(codigoMoeda)
    this.moeda = MOEDA.moeda
    this.idioma = MOEDA.idioma
    this.antes = MOEDA.antes
    this.simbolo = MOEDA.simbolo
    this.milhar = MOEDA.milhar
    this.decimal = MOEDA.decimal
    // this.formata = this.formata
  }

  /** Retorna a moeda selecionada ou a moeda padrão
   * se nenhuma for selecionada
   * @param {String} codigoMoeda Código ISO da moeda desejada
   */
  moedas (codigoMoeda) {
    const MOEDA_PREFERENCIAL = 'BRL'
    const MOEDA_PADRAO = 'USD'
    const MOEDAS = {
      BRL: {
        moeda: 'BRL',
        idioma: 'pt-BR',
        simbolo: 'R$',
        antes: true,
        decimal: ',',
        milhar: '.'
      },
      USD: {
        moeda: 'USD',
        idioma: 'en-US',
        simbolo: '$',
        antes: true,
        decimal: '.',
        milhar: ','
      },
      EUR: {
        moeda: 'EUR',
        idioma: 'pt-PT',
        simbolo: '€',
        antes: false,
        decimal: ',',
        milhar: '.'
      },
      GBP: {
        moeda: 'GBP',
        idioma: 'en-GB',
        simbolo: '£',
        antes: true,
        decimal: '.',
        milhar: ','
      }
    }
    if (!codigoMoeda) codigoMoeda = MOEDA_PREFERENCIAL
    return MOEDAS[codigoMoeda.toUpperCase()] || MOEDAS[MOEDA_PADRAO]
  }

  /** Função de formatação monetária
   * @param {Number} valor Valor a ser formatado
   * @param {Boolean} simboloAntes Se informado, força o símbolo monetário antes do valor
   * @returns {String}
   */
  formata (valor, simboloAntes) {
    let [inteiro, decimal] = valor.toFixed(2).split('.')
    let antes = simboloAntes ? true : this.antes
    let formatado = this.formataMilhar(inteiro, this.milhar)
    formatado = this.uneDecimal(formatado, this.decimal, decimal)
    formatado = this.posicionaSimbolo(formatado, antes, this.simbolo)
    return formatado
  }

  /** Devolve um valor formatado com separadores de milhar
   * @param {String} inteiro Parte inteira do numeral a ser dividido em milhares
   * @param {String} separador Separador de milhar a ser utilizado na formatação
   * @returns {Array}
   */
  formataMilhar (inteiro, separador) {
    return this.divideMilhar(inteiro).join(separador)
  }

  /** Quebrar um numeral em blocos de milhar
   * @param {String} inteiro Parte inteira do numeral a ser dividido em milhares
   * @param {String} separador Separador de milhar a ser utilizado na formatação
   * @returns {Array}
   */
  divideMilhar (inteiro, dividido = []) {
    if (inteiro.length === 0) return dividido
    let inteiroArray = Array.from(inteiro)
    dividido = Array.prototype.concat(inteiroArray.splice(-3).join(''), dividido)
    return this.divideMilhar(inteiroArray.join(''), dividido)
  }

  /** Une as partes inteira e decimal da moeda formatada
   * @param {String} inteiro Parte inteira do valor
   * @param {String} decimal Parte decimal do valor
   */
  uneDecimal (inteiro, separador, decimal) {
    return `${inteiro}${separador}${decimal}`
  }

  /** Função que aplica o símbolo na moeda formatada
   * respeitando a posição padrão
   * @param {String} valor Valor formatado
   * @param {Boolean} antes Flag que indica se o símbolo vem antes ou depois do valor
   * @param {String} simbolo Símbolo da moeda
   * @returns {String}
   */
  posicionaSimbolo (valor, antes, simbolo) {
    if (antes) return `${simbolo} ${valor}`
    return `${valor} ${simbolo}`
  }
}
