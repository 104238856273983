import React, { Component } from 'react'
import styled from 'styled-components'
import * as CONFIG from '../config.json'
import Loading from '../Componentes/Loading'
import ReactTable from 'react-table'
const token = 'JWT ' + sessionStorage.getItem('token')

const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 30px 20px;
`

const TableHeaders = [
  {
    accessor: "hotelName",
    Header: "Hotel",
    Cell: row => (
      <div style={{ textAlign: "center" }}>{row.value}</div>
    )
  },
  {
    accessor: "day",
    Header: "Dia",
    Cell: row => (
      <div style={{ textAlign: "center" }}>{row.value}</div>
    )
  },
  {
    accessor: "totalRooms",
    Header: "Total de quartos vendidos",
    Cell: row => (
      <div style={{ textAlign: "center" }}>{row.value}</div>
    )
  },
  {
    accessor: "totalGuests",
    Header: "Quantidade de hóspedes",
    Cell: row => (
      <div style={{ textAlign: "center" }}>{row.value}</div>
    )
  },
  {
    accessor: "transfersSold",
    Header: "Transfers vendidos",
    Cell: row => (
      <div style={{ textAlign: "center" }}>{row.value}</div>
    )
  }]

  const Resumo = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  & li {
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 3px 3px rgba(72,72,72,0.1);
    -moz-box-shadow: 0px 0px 3px 3px rgba(72,72,72,0.1);
    box-shadow: 0px 0px 3px 3px rgba(72,72,72,0.1);
    width: 100%;
    margin: 15px;
    font-size: 1.2rem;
    padding: 20px;
    text-align: center;
    @media (min-width: 650px) {
      width: calc(50% - 30px);
    }
    @media (min-width: 1024px) {
      width: calc(25% - 60px);
    }
    & p:first-child {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #48484848;
    }
    & p:not(:first-child) {
      color: #00ade2;
      font-weight: bold;
    }
  }
`

export default class Transfer extends Component {
  constructor() {
    super()
    this.state = {
      dados: [],
      total: 0,
      loading: true
    }
  }

  async componentWillMount() {
    let body = document.querySelector('body')
    body.style.overflow = 'hidden'
    await fetch(CONFIG.API.TRANSFER, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      },
    }).then(response => {
      return response.json()
    })
    .then(json => {
      var total = json.reduce((total, item) => total + item.transfersSold, 0)
      this.setState({
        loading: false,
        dados: json,
        total
      }, () => body.style.overflow = 'auto')
    })
    .catch(error => console.log("Erro: " + error))
  }

  render() {
    return (
      <Container>
        <Loading isLoading={this.state.loading} />
        {/* <DadosDoTransfer>
          <Linha>
            <li>DATA</li>
            <li>HOTEL</li>
            <li>TOTAL DE RESERVAS</li>
            <li>TRANSFERS VENDIDOS</li>
          </Linha>
          {this.state.dados.map(function (item, i) {
            return <Linha key={i} className='dados'>
                <li>{item.day}</li>
                <li>{item.hotelName}</li>
                <li>{item.totalGuests}</li>
                <li>{item.transfersSold}</li>
              </Linha>
          })}
        </DadosDoTransfer> */}
        <Resumo>
          <li>
            <p>Total de transfers vendidos </p>
            <p>{this.state.total}</p>
          </li>
        </Resumo>
        <ReactTable
          defaultPageSize={20}
          data={this.state.dados}
          columns={TableHeaders}
          expanderColumnWidth={35}
          previousText={'Anterior'}
          nextText={'Próxima'}
          loadingText={'Carregando...'}
          noDataText={'Nenhum dado encontrado'}
          pageText={'Página'}
          ofText={'de'}
          rowsText={'linhas'}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]).toLowerCase().indexOf(String(filter.value).toLowerCase()) > -1 }
          />
      </Container>
    )
  }
}
