import React, { Component } from 'react'
import styled from 'styled-components'
import ReactTable from 'react-table'
import * as CONFIG from '../config.json'
import Loading from '../Componentes/Loading'
import Dinheiro from '../Componentes/Dinheiro'
const token = 'JWT ' + sessionStorage.getItem('token')

const TableHeaders = [{
  accessor: "id_reserva",
  Header: "ID"
}, {
  accessor: "nome_hotel",
  Header: "Hotel",
  width: 350
}, {
  accessor: "nome_quarto",
  Header: "Quarto",
  width: 250
}, {
  accessor: "capacidade",
  Header: "Capacidade"
}, {
  accessor: "data_entrada",
  Header: "Data Entrada",
  width: 160
}, {
  accessor: "data_saida",
  Header: "Data Saída",
  width: 160
}, {
  accessor: "data_pedido",
  Header: "Data Pedido",
  width: 175
}, {
  accessor: "diarias",
  Header: "Qtd Diárias"
}, {
  accessor: "valor_diaria",
  Header: "Valor da Diária",
  Cell: row => ((new Dinheiro('BRL')).formata(row.value)),
  width: 180
}, {
  accessor: "nome",
  Header: "Nome"
}, {
  accessor: "sobrenome",
  Header: "Sobrenome"
}, {
  accessor: "hospedes",
  Header: "Hóspedes",
  width: 450
}, {
  accessor: "payment_status",
  Header: "Status Pagamento",
  width: 250
}, {
  accessor: "pay_at_hotel",
  Header: "Tipo de Pagamento",
  width: 250
}, {
  accessor: "contrapartida",
  Header: "Contrapartida RiR",
  Cell: row => ((new Dinheiro('BRL')).formata(row.value)),
  width: 250
}]

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  flex-wrap: wrap;
`

const Logo = styled.img`
  max-width: 300px;
  margin-bottom: 20px;
  padding: 0 50px;
`

const Texto = styled.div`
  width: 100%;
  max-width: 640px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.5;
`

const Reservas = styled.p`
  font-size: 3rem;
  color: #02addb;
`

const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px;
`

export default class TelaInicial extends Component {
  constructor () {
    super()
    this.state = {
      bookings: [],
      loading: true
    }
  }

  async componentDidMount () {
    let body = document.querySelector('body')
    body.style.overflow = 'hidden'
    await window.fetch(CONFIG.API.BOOKINGS, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      },
    }).then(response => {
      return response.json()
    }).then(json => {
      this.setState({
        loading: false,
        dadosDeReservas: json.rows,
        totalDeReservas: json.total
      }, () => body.style.overflow = 'auto')
    }).catch(error => {
      console.log("Erro: " + error)
    })
  }

  render () {
    return <div>
      <Loading isLoading={this.state.loading} />
      <Header>
        <Logo src='/imagens/logo-rir.png' />
        <Texto>
          <p>As reservas são atualizadas automaticamente pelo sistema, em tempo real. O total de reservas solicitadas até o momento é de:</p>
          <Reservas>{this.state.totalDeReservas}</Reservas>
        </Texto>
      </Header>
      <Container>
        <ReactTable
          defaultPageSize={20}
          data={this.state.dadosDeReservas}
          columns={TableHeaders}
          expanderColumnWidth={35}
          previousText={'Anterior'}
          nextText={'Próxima'}
          loadingText={'Carregando...'}
          noDataText={'Nenhum dado encontrado'}
          pageText={'Página'}
          ofText={'de'}
          rowsText={'reservas'}
          filterable
          defaultFilterMethod={(filter, row) => {
            return String(row[filter.id]).toLowerCase().indexOf(String(filter.value).toLowerCase()) > -1
          }}
        />
      </Container>
    </div>
  }
}
