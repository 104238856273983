import React from 'react'
import styled from 'styled-components'

const FooterWrapper = styled.footer`
  background-color: #333;
  padding: 20px;
`

const Footer = styled.ul`
  color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  list-style: none;
  text-align: center;
  align-content: center;
  align-items: center;
  line-height: 2;
  @media (min-width: 850px) {
    flex-direction: row;
  }
`

const Linha = styled.li`
  width: 100%;
  & img {
    max-width: 150px;
  }
  @media (min-width: 850px) {
    width: 33.333%;
    flex-direction: row;
  }
`


export default function () {
  return (
    <FooterWrapper>
      <Footer>
        <Linha><img src='/imagens/evnts.png' alt='' /></Linha>
        <Linha>Copyright © {new Date().getFullYear()} <a rel="noopener noreferrer" href='https://evnts.com.br/' target='_blank'>evnts.com.br</a> - Todos os direitos reservados</Linha>
        <Linha>Feito com ♥ pela Evnts</Linha>
      </Footer>
    </FooterWrapper>
  )
}