import React, { Component } from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import * as CONFIG from '../config.json'

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 245px);
  @media (min-width: 500px) {
  min-height: calc(100vh - 213px);
  }
  @media (min-width: 850px) {
    min-height: calc(100vh - 156px);
  }
  @media (min-width: 1420px) {
    min-height: calc(100vh - 149px);
  }
  @media (min-width: 920px) {
    flex-direction: row;
  }
  justify-content: center;
  padding: 20px;
`

const Logo = styled.img`
  max-width: 200px;
  width: 100%;
`

const Form = styled.form`
  width: 100%;
  max-width: 480px;
  padding: 20px;
`

const InputGroup = styled.div`
  margin-bottom: 20px;
`

const Label = styled.label`
  color: #fff;
  margin-bottom: 5px;
  display: block;
  font-size: 1.075rem;
`

const Input = styled.input`
  border: 1px solid #ccc;
  padding: 7px 10px;
  width: 100%;
  border-radius: 3px;
  &[data-valid='true'] {
    border-bottom: 2px solid #009688;
  }
  &[data-valid='false'] {
    border-bottom: 2px solid #ff0000;
  }
`

const Submit = styled.button`
  background-color: #02addb;
  width: 100%;
  border-radius: 3px;
  display: block;
  padding: 15px 0;
  font-size: 1.105rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  outline: none;
  border: none;
  margin-top: 30px;
  cursor: pointer;
`

export default class username extends Component {
  constructor() {
    super()
    this.state = {
      username: '',
      password: '',
      invalidForm: false
    }

    this.submit = this.submit.bind(this)
    this.focusInput = this.focusInput.bind(this)
  }

  validateInput (input) {
    input.dataset.valid = R.isEmpty(input.value) ? 'false' : 'true'
  }

  focusInput (e) {
    e.target.dataset.valid = ''
  }

  submit (e) {
    let { username, password } = this.state
    e.preventDefault()
    this.validateInput(username)
    this.validateInput(password)

    if (R.isEmpty(username.value) || R.isEmpty(password.value)) {
      alert('Atente-se aos campos vazio e/ou vermelho!')
    } else {
      fetch(CONFIG.API.AUTH, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username.value,
          password: password.value
        })
      })
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        if (response.token) {
          sessionStorage.setItem('token', response.token)
          sessionStorage.setItem('expiry', response.expiry)
          sessionStorage.setItem('isAuthenticated', 'true')
          window.location.replace('./')
        } else {
          alert(response)
        }
      })
    }
  }

  render () {
    return (
      <LoginWrapper>
        <Logo src='/imagens/logo-rir.png' />
        <Form onSubmit={this.submit}>
          <InputGroup>
            <Label>Login</Label>
            <Input
              id='username'
              name='username'
              onFocus={this.focusInput}
              // eslint-disable-next-line
              ref={input => this.state.username = input }
            />
          </InputGroup>
          <InputGroup>
            <Label>Senha</Label>
            <Input
              type='password'
              name='senha'
              id='senha'
              onFocus={this.focusInput}
              // eslint-disable-next-line
              ref={input => this.state.password = input }
            />
          </InputGroup>
          <Submit type='submit'>entrar</Submit>
        </Form>
      </LoginWrapper>
    )
  }
}