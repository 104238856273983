import React from 'react'
import styled from 'styled-components'
import NavMenu from './NavMenu'

const NavbarWrapper = styled.nav`
  width: 100%;
  padding: 10px 0px;
  background-color: #031027;
  min-height: 52px;
`

const Logo = styled.img`
  max-height: 32px;
  position: absolute;
  top: 10px;
  left: calc(50% - 51px);
`

const Navbar = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Item = styled.a`
  color: #fff;
  padding: 10px 40px;
  display: block;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
  border-bottom: ${props => props.active ? '2px solid #fff': 'none'};
`

export default function (props) {
  return (
    <div>
      <NavbarWrapper role="navigation">
        {props.isAuthenticated
          ? (<Navbar>
            <NavMenu />
            <li><Logo src='/imagens/evnts.png' /></li>
            <li><Item onClick={props.handleLogout}>Sair</Item></li>
          </Navbar>)
          : <Logo src='/imagens/evnts.png' />
        }
      </NavbarWrapper>
    </div>
  )
}