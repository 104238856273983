import React from 'react'
import styled from 'styled-components'

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255,255,255,0.85);
  z-index: 2;
  display: ${props => (!!props.isLoading) ? 'block' : 'none'};
  & > img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    margin: 0 auto;
  }
`

export default function (props) {
  return (
    <LoadingWrapper {...props}>
      <img alt='' src="/imagens/loading.gif" />
    </LoadingWrapper>
  )
}
