import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const MobileNav = styled.ul`
  overflow: hidden;
`

const Menu = styled.ul`
  position: absolute;
  top: 56px;
  left: 0;
  background-color: #031027;
  width: 120px;
  padding: 10px 20px;
  display: ${props => props.mobile ? 'flex' : 'none'};
  line-height: 3;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  color: #fff;
  & a {
    display: block;
  }
  @media (min-width: 1024px) {
    width: auto;
    padding: 0;
    line-height: 1;
    background-color: unset;
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: unset;
    margin-top: 10px;
    & li {
      margin-right: 40px;
      padding-bottom: 10px;
    }
    & .active {
      border-bottom: 1px solid #fff;
    }
  }
`

const MenuToggle = styled.li`
  position: relative;
  width: 120px;
  text-align: center;
  color: #fff;
  @media (min-width: 1024px) {
    display: none;
  }
`

export default class NavMenu extends Component {
  constructor() {
    super()
    this.state = {
      mobile: false
    }
  }
  
  render () {
    return (
      <MobileNav>
        <MenuToggle onClick={() => this.setState({ mobile: !this.state.mobile })}>Menu</MenuToggle>
        <Menu mobile={this.state.mobile} onMouseLeave={() => this.setState({ mobile: false })}>
          <li className={window.location.pathname === '/' ? 'active' : 'none'}><Link to="/">Listagem</Link></li>
          <li className={window.location.pathname === '/financeiro' ? 'active' : 'none'}><Link to="/financeiro">Financeiro</Link></li>
          <li className={window.location.pathname === '/ingressos' ? 'active' : 'none'}><Link to="/ingressos">Ingressos</Link></li>
          <li className={window.location.pathname === '/transfer' ? 'active' : 'none'}><Link to="/transfer">Transfer</Link></li>
        </Menu>
      </MobileNav>
    )
  }
}