import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './Routes'
import Navbar from './Componentes/Navbar'
import Rodape from './Componentes/Rodape'

let isAuthenticated = JSON.parse(sessionStorage.getItem('isAuthenticated'))

export default class App extends Component {
  componentDidMount () {
    const body = document.querySelector('body')

    body.style.background = window.location.pathname.toLowerCase().indexOf('rockinrio') > -1
      ? "url('/imagens/background-rir.jpg') center top no-repeat"
      : "url('/imagens/bodybg.png') center bottom no-repeat"

    body.style.backgroundSize = 'cover'
  }

  handleLogout = evento => {
    evento.preventDefault()
    sessionStorage.clear()
    window.location.replace('./login')
  }

  render () {
    return <Router>
      <div>
        <div>
          <Navbar isAuthenticated={isAuthenticated} handleLogout={this.handleLogout} />
          <Routes />
        </div>
        <Rodape />
      </div>
    </Router>
  }
}
